//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {iata} from '@/apis/tool.js'

export default {
	data: ()=> ({
		query: '',
		dataSource: [],
		inRNBox: false,
		navigationBarBackgroundColor: '#437AFF',
	}),
	created() {
		this.inRNBox = window.ReactNativeWebView || !!this.$route.query.inRNBox || false
		if(this.inRNBox) {
			this.navigationBarBackgroundColor = '#' + (this.$route.query.navigationBarBackgroundColor || '154B79')
		}
	},
	mounted() {
		this._getList({en: 'SHA'})
	},
	methods: {
		async _getList(params) {
			let {data = []} = await iata(params)
			if( data.length>0 ){
				let result= []
				for(const item of data){ 
					if(item){
						for(const portsItem of item.ports){
							result.push(portsItem)
						}
					}
				}
				this.dataSource = result
			}else{
				this.dataSource = []
			}
		},
		async onSearch() {
			this.query = this.query.toLocaleUpperCase()
			this.query = this.query ? this.query.trim() : ''
			let params = {
				cn: '',
				en: ''
			}
			if([...this.query].length < 3) {
				this.$toast('请核对输入的三字码')
				return
			}
			if(/^[A-Z]{2,3}$/.test(this.query)) {
				params.en = this.query
			} else {
				params.cn = this.query
			}
			await this._getList(params)
		},
		showDetail(title, list) {
			this.$dialog.alert({
				title,
				message: list.replace(/\$/g, '\n'),
			});
		}
	}
}
